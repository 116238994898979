@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".SideNav";

#{$selector} {
  display: flex;
  flex-direction: column;

  &__item__label {
    display: flex;
    flex-direction: row;
    margin: $space-xs;
  }

  &__item__link {
    flex-grow: 1;
  }

  &__item__icon {
    flex: 0 0 15px;
    fill: $icon-base;
  }

  &__item__title {
    @include text("component/menu/link");
    padding-left: $space-md;
    font-size: 15px;

    &--has-link {
      @include when-hovered {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  &__item__accordion {
    position: unset !important;
    margin: 0 !important;
    background-color: transparent;
    box-shadow: none !important;

    &__wrapper {
      min-height: auto !important;
      padding: 0 !important;
    }

    &__content {
      margin: 0 !important;
    }

    &__tab {
      flex-grow: 1;
    }

    &__label {
      display: flex;
      flex-direction: row;
      padding: $space-xs;
    }

    &__icon {
      flex: 0 0 15px;
      fill: $icon-base;
    }

    &__title {
      @include text("component/menu/link");
      padding-left: $space-md;
      font-size: 15px;

      @include when-hovered {
        text-decoration: underline;
      }
    }
  }

  &__list {
    padding-left: $space-md;
    list-style-type: disc !important;

    &__title,
    &__link {
      @include text("component/menu/link");
      font-weight: 400;
      cursor: pointer;

      @include when-hovered {
        text-decoration: underline;
      }
    }
  }
}
