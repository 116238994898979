@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopDetailHeaderContent";

#{$selector} {
  &__content {
    &__title {
      @include text("headline/xl");
    }

    &__desc {
      @include text("base");
    }
  }

  &__price {
    display: flex;
    flex-direction: column;

    &__wrapper {
      display: flex;
    }

    &__label {
      @include text("headline/xs");
      margin-bottom: $space-md;
    }

    &__discount {
      @include text("headline/md");
      color: $text-error;
    }

    &__value {
      @include text("headline/md");
      margin-bottom: $space-sm;
      color: $text-primary;

      &--has-discount {
        @include text("component/productCard/price");
        padding-left: $space-md;
        color: $text-primary;
        text-decoration: line-through;
        opacity: 0.6;
      }
    }

    &__desc {
      @include text("meta");
    }

    &__content {
      @include text("meta");
      padding-top: $space-lg;
    }
  }

  &__variants {
    display: grid;
    grid-gap: 0 $space-md;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: $space-xl;

    @include screenSmallerThan(1200px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include screenSmallerThan(900px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__variant {
    position: relative;
    margin-bottom: $space-md;
    padding: $space-xl $space-md;
    background-color: $background-base;
    border: 2px solid $border-lightgray;
    border-radius: 5px;
    box-shadow: $ui-elevation-button;
    cursor: pointer;

    &__tag {
      @include text("meta");
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 1;
      padding: $space-xs $space-sm;
      color: $text-white;
      background-color: $background-primary;
      border-radius: 1rem;
    }

    &__title {
      @include text("headline/sm");
      display: block;
      padding-bottom: $space-sm;
      text-align: center;
    }

    &__price {
      @include text("headline/sm");
      display: block;
      margin-bottom: $space-sm;
      color: $text-base;
      font-weight: 700;
      text-align: center;

      &__unit {
        color: $text-base;
      }

      &--has-discount {
        color: $text-error;
      }
    }

    &--is-active {
      background-color: transparent;
      border-color: $border-primary;
      transition: border-color 0.3s linear;

      &__price {
        color: $text-primary;
      }
    }

    @include when-hovered {
      background-color: transparent;
      border-color: $border-primary;
      transition: border-color 0.3s linear;
    }
  }

  &__button {
    margin-top: $space-md;

    &__helper {
      @include text("meta");
      padding-top: $space-md;
    }
  }
}
