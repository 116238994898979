@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopDetailHeaderImages";

#{$selector} {
  display: flex;
  align-items: center;
  justify-content: center;

  @include screenSmallerThan(782px) {
    margin-bottom: $space-xl;
  }
}
