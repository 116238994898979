@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverview";

#{$selector} {
  display: flex;
  background-color: rgba($background-primary, 0.05);

  @include screenSmallerThan(1200px) {
    flex-direction: column;
  }

  &__navigation {
    width: 33.333%;
    padding-right: $space-md;

    @include screenSmallerThan(1200px) {
      width: 100%;
      padding-right: 0;
    }
  }

  &__products {
    width: 77.777%;
    padding-left: $space-md;

    @include screenSmallerThan(1200px) {
      width: 100%;
    }
  }
}
