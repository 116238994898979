@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewProducts";

#{$selector} {
  display: grid;
  grid-gap: 0 $space-md;
  grid-template-columns: 1fr 1fr 1fr;

  &__product {
    display: flex;
  }

  @include screenSmallerThan(900px) {
    grid-template-columns: 1fr 1fr;
  }

  @include screenSmallerThan(630px) {
    grid-template-columns: 1fr;
  }
}
