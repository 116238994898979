@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".Gallery";

#{$selector} {
  &__image {
    position: relative !important;
    display: none;
    height: auto !important;

    &--is-active {
      display: block;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;

    &__placeholder {
      align-self: center;
      width: 50% !important;
      height: 50% !important;
    }
  }

  &__pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__item {
      position: relative;
      width: 80px;
      height: 80px;
      margin: $space-sm;
      background-color: $background-base;
      border-radius: 10px;
      cursor: pointer;

      &--is-active {
        border: 1px solid $border-primary;
      }
    }
  }
}
