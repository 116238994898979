@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopOverviewProductsHeader";

#{$selector} {
  margin-bottom: $space-lg;

  &__content {
    display: grid;
    grid-gap: 0 $space-md;
    grid-template-columns: 2fr 1fr;
  }

  &__input {
    height: 56px;
    background-color: $background-base;
  }
}
